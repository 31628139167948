<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <b-row>
                <b-col cols="12">
                    <ValidationProvider name="type" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('type')">
                            <constant-type-selectbox v-model="formData.type"
                                                     :validate-error="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12">
                    <ValidationProvider name="value" rules="required" v-slot="{valid, errors}">
                        <b-form-group v-if="formData.type != null"
                                      :label="$t('value')"
                        >
                            <div v-if="formData.type=='file'" class="d-flex">
                                <div class="col-10">
                                    <b-form-group :label="$t('file')">
                                        <div class="d-flex custom-file-upload">
                                            <b-form-file
                                                v-model="formData.file2"
                                                :placeholder="formData.value"
                                                :drop-placeholder="$t('drop_file')"
                                                ref="fileInput"
                                                multiple></b-form-file>
                                            <b-button variant="outline-secondary"
                                                      @click="$refs.fileInput.$el.childNodes[0].click();">
                                                {{ $t('browse') }}
                                            </b-button>
                                        </div>
                                        <div class="alert alert-info mt-2">{{
                                                $t('only_pdf_files_can_be_uploaded')
                                            }}
                                        </div>
                                    </b-form-group>
                                </div>
                                <b-col class="col-4" v-if="showPDFButton">
                                    <div class="mt-5"
                                    >
                                        <b-button squared variant="primary" v-b-tooltip.hover :title="$t('show')"
                                                  @click="showPdf(formData)">
                                            <i class="ri-file-upload-line" style="font-size: 25px"></i>
                                        </b-button>
                                    </div>

                                </b-col>
                            </div>
                            <div v-if="formData.type=='string'">
                                <b-form-input type="text" v-model="formData.value"
                                              :state="errors[0] ? false : null"/>
                            </div>
                            <div v-else-if="formData.type=='int'">
                                <b-form-input type="number" v-model="formData.value"
                                              :state="errors[0] ? false : null"/>
                            </div>
                            <div v-else-if="formData.type=='comma'">
                                <b-form-textarea v-model="formData.value"
                                                 rows="3"
                                                 max-rows="6"
                                />
                            </div>
                            <div v-else-if="formData.type=='json'">
                                <b-form-textarea v-model="formData.value"
                                                 rows="3"
                                                 max-rows="6"
                                />
                            </div>
                            <div v-else-if="formData.type=='bool'">
                                <b-form-select v-model="formData.value"
                                               :options="boolOptions"/>
                            </div>
                            <div v-else-if="formData.type=='date'">
                                <select-date v-model="formData.value"
                                             :validation-error="errors[0]"
                                             :is-time="true"
                                />
                            </div>
                            <div v-else-if="formData.type=='semester'">
                                <semesters-selectbox v-model="formData.value"
                                                     :validate-error="errors[0]"/>
                            </div>
                            <div v-else-if="formData.type=='html'">
                                <ckeditor v-model="formData.value"/>
                            </div>
                            <div v-else-if="formData.type=='person'">
                                <staff-auto-complete v-model="formData.value"
                                                     :valueObject="personObj"
                                                     :validate-error="errors[0]"/>
                            </div>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
            </b-row>
            <div class="d-flex justify-content-center mt-2">
                <b-button variant="primary"
                          @click="updateForm"
                          :disabled="formLoading">
                    {{ $t('update') }}
                </b-button>
            </div>

        </ValidationObserver>
    </div>
</template>

<script>

// Services
import ConstantService from '@/services/ConstantService'
import PersonService from "@/services/PersonService";

// Components
import ConstantTypeSelectbox from '@/components/interactive-fields/ConstantTypeSelectbox'
import SelectDate from '@/components/interactive-fields/SelectDate'
import SemestersSelectbox from '@/components/interactive-fields/SemestersSelectbox'
import StaffAutoComplete from "@/components/interactive-fields/StaffAutoComplete";

// Other
import {ValidationObserver, ValidationProvider} from 'vee-validate'

export default {
    components: {

        ConstantTypeSelectbox,
        SelectDate,
        SemestersSelectbox,
        StaffAutoComplete,
        ValidationProvider,
        ValidationObserver
    },
    props: {
        formId: {
            type: Number
        }
    },
    data() {
        return {
            showPDFButton: false,
            formData: {
                key: null,
                value: null,
                type: null,
                file: []
            },
            formLoading: false,

            boolOptions: [
                {value: null, text: this.$t('enter_value'), disabled: true},
                {value: 'true', text: this.$t('true')},
                {value: 'false', text: this.$t('false')}
            ],
            personObj: {}
        }
    },
    watch: {
        formId: function (val) {
            this.get(val)
        },
        'formData.file'(newValue) {
            newValue === null ? this.showPDFButton = false : this.showPDFButton = true
        }

    },
    async created() {

        await this.get(this.formId)
    },
    methods: {
        get(id) {
            ConstantService.get(id)
                .then(response => {
                    let data = response.data.data
                    this.formData = {
                        file: data.file ?? null,
                        key: data.key,
                        value: data.value,
                        type: data.type
                    }

                    if(this.formData.type == 'person'){
                        PersonService.get(this.formData.value).then(response => {
                            this.personObj = {
                                text: response.data.data.name+' '+response.data.data.surname,
                                value: this.formData.value
                            }
                        })
                    }
                })
                .catch(e => {
                    this.showErrors(e)
                })
        },
        async updateForm() {
            if (this.checkPermission('constant_update')) {
                const isValid = await this.$refs.formModalValidate.validate();
                if (isValid && this.formId) {
                    let config = {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                    let formData = new FormData()
                    if (this.formData.type == 'file') {
                        let fileName = this.formData.file2[0].name
                        formData.append('file', this.formData.file2[0]);
                        this.formData.value = fileName
                    }
                    formData.append('_method', 'PUT')
                    formData.append('key', this.formData.key);
                    formData.append('value', this.formData.value);
                    formData.append('type', this.formData.type);
                    this.formLoading = true;
                    ConstantService.update(this.formId, formData, config)
                        .then(response => {
                            this.$emit('updateFormSuccess', true);
                            this.$toast.success(this.$t('api.' + response.data.message));
                        })
                        .catch(e => {
                            this.showErrors(e, this.$refs.formModalValidate);
                        })
                        .finally(() => {
                            this.formLoading = false
                        })
                }
            }
        },
        showPdf(data) {
            ConstantService.downloadFile(data.key).then(response => {
                this._downloadFile(response, data.value)
            }).catch(e => {
                this.showErrors(e)
            })
            /*
            let base64Decode = atob(response.file)
            let arrayBufferData =this.convertToBuffer(base64Decode)
            response.data=arrayBufferData
            response.headers={'content-type': 'application/json'}
            // response.responseType='arraybuffer'
            this._downloadFile(response,response.value)

             */
        },
        /*
        convertToBuffer(data) {
            //bu function base64 ile gelen veriyi arraybuffera çevirir
            const buffer = new ArrayBuffer(data.length);
            const view = new Uint8Array(buffer);
            for (let i = 0; i < data.length; i++) {
                view[i] = data.charCodeAt(i) & 0xff;
            }
            return buffer;
        },
        */

    }
}
</script>
